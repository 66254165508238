.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #d7d8da;
  --ion-color-tint: #f5f6f9;
}

:root {
  --ion-font-family: "Fira Sans",
    sans-serif;
  --ion-color-primary: #C7102E;
  --ion-color-primary-rgb: 199,
    16,
    46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,
    255,
    255;
  --ion-color-primary-shade: #af0e28;
  --ion-color-primary-tint: #cd2843;
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12,
    209,
    232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,
    255,
    255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,
    68,
    255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,
    255,
    255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,
    220,
    96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,
    255,
    255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,
    206,
    0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,
    255,
    255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,
    61,
    61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,
    255,
    255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,
    34,
    34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
    255,
    255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,
    154,
    162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
    255,
    255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,
    244,
    244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
    0,
    0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255,
    255,
    255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,
    0,
    0;
  --ion-color-white-shade: darker(--ion-color-white, 25%);
  --ion-color-white-tint: lighten(--ion-color-white, 25%);
}