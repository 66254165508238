// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './dragula.scss';
@import './animations.scss';

body {
  font-family: --ion-font-family;
  //font-weight: 400;
  letter-spacing: 1px;
  font-weight: 400;
}

.bold-item {
  font-family: --ion-font-family;
  //font-weight: 600;
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.price-badge {
  font-size: 16px;
  border-radius: 0;
  padding-right: 30px;
}

.added-to-cart {
  text-align: center;
  --background: var(--ion-color-succes, #0ec254) !important;
  opacity: 0.9;
}

.geo-location-failed {
  text-align: center;
  --background: var(--ion-color-danger-shade, #f04141) !important;
  opacity: 0.9;
}

.padding-right-16 {
  padding-right: 16px;
}

.fab-icon {
  font-size: 40px;
}

.restaurant-background {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.restaurant-logo {
  // margin: auto;
  max-height: 20vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dark-text {
  color: var(--ion-color-dark, #222) !important;
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none; // Chrome all / Safari all
  -moz-user-select: none; //Firefox all
  -ms-user-select: none; // IE 10
  user-select: none;
}

.error {
  color: var(--ion-color-danger-shade, #f04141) !important;
}

.success {
  color: var(--ion-color-success-shade, #0ec254) !important;
}

.rounded {
  border-radius: 20px;
}

.rounded-bottom {
  border-radius: 0 0 20px 20px;
}

.menu-row {
  min-height: 72px;
  min-width: 72px;
  margin-top: -14px;

}

.menu-btn {
  position: relative;

  //left:-40px;
  & h3 {
    font-size: 20px;
  }
}

.menu-row>ion-col>ion-button>ion-label>h3 {
  font-weight: 600;
}

.main-action-button {
  --border-radius: 30px;
}

.version {
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  padding-right: 1em;
  font-size: 10px;
  line-height: 17px;
  color: var(--ion-color-light);
  font-family: var(--ion-font-family);
}

.feedback {
  padding-left: 7px;
  padding-top: 2px;
  font-size: 12px;
  color: var(--ion-color-light);
  font-family: var(--ion-font-family);
  text-decoration: underline;
  cursor: pointer;
}

.power-by-container {
  max-height: 48px;
}

.power-by {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  font-size: 10px;
  width: 115px;
  margin: 0px auto;
  color: #636363;
}

.power-by-container a {
  text-decoration: none;
}

.line {
  width: 90%;
  height: 2px;
  border-bottom: 1px solid rgb(216, 216, 216);
  position: relative;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.three-b-apps-logo {
  width: 20px;
  margin: 0px 5px;

  .st0 {
    fill: #000000;
  }

  .st1 {
    fill: #ff6a00;
  }

  .st2 {
    fill: #fc0011;
  }

  .st3 {
    fill: #1cba04;
  }

  .st4 {
    fill: #4bb8a7;
  }

  .st5 {
    fill: #ffffff;
  }
}

.modal-wrapper.sc-ion-modal-md {
  min-height: 82% !important;
}

@media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  ion-toolbar {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

.less-padding {
  padding: 6px 4px 8px 6px !important;
}

@media screen and (max-width: 720px) {
  .less-padding-sm {
    padding: 6px 4px 8px 6px !important;
  }

  .smaller-font-sm {
    font-size: 85% !important;
  }

  ion-card-title {
    padding: 0 8px;
  }

  .restaurant-logo {
    max-width: 90%;
    // padding-bottom: 10%;
  }
}

.no-padding {
  padding: 0 !important;
}

//  https://css-tricks.com/the-notch-and-css/
ion-header,
.user-container {
  padding-top: env(safe-area-inset-top);
  background: var(--ion-color-dark);
}