@keyframes ion-pulsate {
  0% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.8;
  }
  20% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  40% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  50% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.3;
  }
  60% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  80% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  100% {
    --background: inherit;
  }
}

.pulsate {
  animation: ion-pulsate 2.5s 1 ease-in;
}

@keyframes ion-pulsate-text {
  0% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.8;
  }
  20% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  40% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  50% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.3;
  }
  60% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  80% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  100% {
    --color: inherit;
  }
}

.pulsate-text {
  animation: ion-pulsate-text 2.5s 1 ease-in;
}
