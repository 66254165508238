@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

@keyframes ion-pulsate {
  0% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.8;
  }
  20% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  40% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  50% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.3;
  }
  60% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  80% {
    --background: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  100% {
    --background: inherit;
  }
}
.pulsate {
  animation: ion-pulsate 2.5s 1 ease-in;
}

@keyframes ion-pulsate-text {
  0% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.8;
  }
  20% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  40% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  50% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.3;
  }
  60% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.5;
  }
  80% {
    --color: var(--ion-color-warning, #f7ca36);
    opacity: 0.7;
  }
  100% {
    --color: inherit;
  }
}
.pulsate-text {
  animation: ion-pulsate-text 2.5s 1 ease-in;
}

body {
  font-family: --ion-font-family;
  letter-spacing: 1px;
  font-weight: 400;
}

.bold-item {
  font-family: --ion-font-family;
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.price-badge {
  font-size: 16px;
  border-radius: 0;
  padding-right: 30px;
}

.added-to-cart {
  text-align: center;
  --background: var(--ion-color-succes, #0ec254) !important;
  opacity: 0.9;
}

.geo-location-failed {
  text-align: center;
  --background: var(--ion-color-danger-shade, #f04141) !important;
  opacity: 0.9;
}

.padding-right-16 {
  padding-right: 16px;
}

.fab-icon {
  font-size: 40px;
}

.restaurant-background {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.restaurant-logo {
  max-height: 20vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dark-text {
  color: var(--ion-color-dark, #222) !important;
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error {
  color: var(--ion-color-danger-shade, #f04141) !important;
}

.success {
  color: var(--ion-color-success-shade, #0ec254) !important;
}

.rounded {
  border-radius: 20px;
}

.rounded-bottom {
  border-radius: 0 0 20px 20px;
}

.menu-row {
  min-height: 72px;
  min-width: 72px;
  margin-top: -14px;
}

.menu-btn {
  position: relative;
}
.menu-btn h3 {
  font-size: 20px;
}

.menu-row > ion-col > ion-button > ion-label > h3 {
  font-weight: 600;
}

.main-action-button {
  --border-radius: 30px;
}

.version {
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  padding-right: 1em;
  font-size: 10px;
  line-height: 17px;
  color: var(--ion-color-light);
  font-family: var(--ion-font-family);
}

.feedback {
  padding-left: 7px;
  padding-top: 2px;
  font-size: 12px;
  color: var(--ion-color-light);
  font-family: var(--ion-font-family);
  text-decoration: underline;
  cursor: pointer;
}

.power-by-container {
  max-height: 48px;
}

.power-by {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  font-size: 10px;
  width: 115px;
  margin: 0px auto;
  color: #636363;
}

.power-by-container a {
  text-decoration: none;
}

.line {
  width: 90%;
  height: 2px;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.three-b-apps-logo {
  width: 20px;
  margin: 0px 5px;
}
.three-b-apps-logo .st0 {
  fill: #000000;
}
.three-b-apps-logo .st1 {
  fill: #ff6a00;
}
.three-b-apps-logo .st2 {
  fill: #fc0011;
}
.three-b-apps-logo .st3 {
  fill: #1cba04;
}
.three-b-apps-logo .st4 {
  fill: #4bb8a7;
}
.three-b-apps-logo .st5 {
  fill: #ffffff;
}

.modal-wrapper.sc-ion-modal-md {
  min-height: 82% !important;
}

@media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  ion-toolbar {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}
.less-padding {
  padding: 6px 4px 8px 6px !important;
}

@media screen and (max-width: 720px) {
  .less-padding-sm {
    padding: 6px 4px 8px 6px !important;
  }

  .smaller-font-sm {
    font-size: 85% !important;
  }

  ion-card-title {
    padding: 0 8px;
  }

  .restaurant-logo {
    max-width: 90%;
  }
}
.no-padding {
  padding: 0 !important;
}

ion-header,
.user-container {
  padding-top: env(safe-area-inset-top);
  background: var(--ion-color-dark);
}